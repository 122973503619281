.content {
    height: calc(100vh - 2rem - 105px);
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content {
    min-height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background-content);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graphs {
    gap: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

.graph {
    background-color: var(--background);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 50vh;
}

.graphTitle {
    text-align: center;
    background-color: var(--background);
    margin: 0;
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 12pt;
    letter-spacing: 0.1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.graphTitle label {
    font-size: 10pt;
}

.monthAndYear {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-left: 130px;
}

.chosenDate {
    background-color: var(--background);
    opacity: 0.9;
    color: var(--text-primary);
    margin-bottom: 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.chosenDate:hover {
    opacity: 1;
}

.chosenDate p {
    text-align: center;
    width: 148px;
    height: 20px;
    margin: 0;
    font-size: 12pt;
    padding: 12px 0;
}

.modalInitialDate {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-right: 150px;
    margin-top: 70px;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    z-index: 100;
}

.modalFinalDate {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-left: 150px;
    margin-top: 70px;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    z-index: 100;
}

.modalGrid {
    display: grid;
    grid-template-columns: repeat(3, 120px);
    padding: 0 10px;
}

.modalGrid p{
    margin: 0;
    padding: 6px 8px;
    font-size: 12pt;
    cursor: pointer;
    border-radius: 4px;
}
.modalGrid p:hover{
    background-color: var(--listitem-hover);
}

.chooseYear {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    user-select: none;
}

.modalYear {
    margin: 10px 0;
}

.searchButton {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-height: 44px;
    height: fit-content;
    padding: 12px 10px;
    margin: 0;
    background-color: var(--button-backgroundcolor);
    opacity: 0.9;
    color: var(--button-textcolor);
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    font-size: 10pt;
    gap: 5px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.searchButton:hover {
    opacity: 1;
    background-color: var(--button-bordercolor);
}

.labelMonths {
    font-size: 10pt;
    font-weight: bold;
    color: var(--placeholder-color);
    text-align: center;
    margin: 0;
    height: 20px;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
    gap: 5px;
    border-radius: 4px;
    padding: 0 100px;
}

.noDataText {
    color: #8f8f8f;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    font-size: 12pt;
    text-transform: uppercase;
}

@media only screen and (max-width: 1436px) {
    .graphs {
        display: flex;
        flex-direction: column !important;
        width: 100%;
    }
    .monthAndYear {
        margin: 0;
    }
    .modalInitialDate {
        margin-right: 280px;
    }
    .modalFinalDate {
        margin-left: 20px;
    }

}
@media only screen and (max-width: 978px) {
    .monthAndYear {
        margin-top: 70px;
    }
    .modalInitialDate {
        margin-top: 140px;
    }
    .modalFinalDate {
        margin-top: 140px;
    }
}