
.form {
    display: flex;
    flex-direction: column;
}

.dropdown {
    display: flex;
}

.dropdownItems {
    position: relative;
}

.dropdownNull {
    display: flex;
}

.dropdownNull p {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

.dropdownLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Lato';
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.dropdown p {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.items {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background-color: var(--input-background);
    box-shadow: 0 3px 7px var(--boxshadow);
    border-radius: 6px;
    animation: rotateMenu 400ms ease-in-out forwards;
    transform-origin: top center;
    max-height: 30vh;
    overflow-y: scroll;
}
.itemsTop {
    z-index: 2;
    box-shadow: 0 -3px 7px var(--boxshadow);
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background-color: var(--input-background);
    border-radius: 6px;
    animation: openFromTop 400ms ease-in-out forwards;
    transform-origin: top center;
    height: 20vh;
    overflow-y: scroll;
}

@keyframes openFromTop {
    0% {
      transform: translateY(-50%) rotateX(-90deg);
    }
    70% {
        transform: translateY(calc(-20vh - 56.8px)) rotateX(20deg) 
    }
    100% {
      transform: translateY(calc(-20vh - 56.8px)) rotate(0deg);
      opacity: 1;
    }
  }

@keyframes rotateMenu {
    0% {
        transform: rotateX(-90deg)
    }
    70% {
        transform: rotateX(20deg) 
    }
    100% {
        transform: rotateX(0deg) 
    }
}

@media only screen and (max-width: 900px) {
    .dropdown p {
        min-width: 100px;
        word-break: break-all;
    }
    .dropdownNull p {
        min-width: 100px;
        word-break: break-all;
    }
}

.item {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    padding: 8px 12px;
    font-size: 13pt;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}
.item:hover {
    background-color: var(--listitem-hover);
    cursor: pointer;
}

.item label {
    font-size: 10px;
}

.search {
    align-self: center;
    width: 90%;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px 0;
    background-color: var(--background);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 5px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: var(--background);
    color: var(--text-primary);
}

.search input:focus + .search{
    border: 1px solid var(--topbar-bordercolor);
}

::placeholder {
    color: var(--topbar-textcolor);
    opacity: 1;
}

.searchIcon {
    color: var(--topbar-textcolor);
}

.loadMore p {
    margin: 10px 0;
    color: var(--placeholder-color);
    font-size: 9pt;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    height: 20px;
}

@keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }