.content {
    height: calc(100vh - 2rem - 105px);
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content {
    min-height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background-content);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graphs {
    gap: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

.graph {
    background-color: var(--background);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 50vh;
}

.graphTitle {
    text-align: center;
    background-color: var(--background);
    margin: 0;
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 12pt;
    letter-spacing: 0.1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.graphTitle label {
    font-size: 10pt;
}

.monthAndYear {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.costs {
    min-width: 180px;
    z-index: 16;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-height: 44px;
    height: fit-content;
    padding: 12px 10px;
    background-color: var(--button-backgroundcolor);
    opacity: 0.9;
    color: var(--button-textcolor);
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    font-size: 10pt;
    gap: 5px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.costs:hover{
    opacity: 1;
}

.chosenDate {
    background-color: var(--background);
    opacity: 0.9;
    color: var(--text-primary);
    margin-bottom: 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.chosenDate:hover {
    opacity: 1;
}

.chosenDate p {
    text-align: center;
    width: 148px;
    height: 20px;
    margin: 0;
    font-size: 12pt;
    padding: 12px 0;
}

.modalInitialDate {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-right: 150px;
    margin-top: 140px;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    z-index: 16;
}

.modalFinalDate {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-left: 150px;
    margin-top: 140px;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    z-index: 16;
}

.modalGrid {
    display: grid;
    grid-template-columns: repeat(3, 120px);
    padding: 0 10px;
}

.modalGrid p{
    margin: 0;
    padding: 6px 8px;
    font-size: 12pt;
    cursor: pointer;
    border-radius: 4px;
}
.modalGrid p:hover{
    background-color: var(--listitem-hover);
}

.chooseYear {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    user-select: none;
}

.modalYear {
    margin: 10px 0;
}

.searchButton {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-height: 44px;
    height: fit-content;
    padding: 12px 10px;
    margin: 0;
    background-color: var(--button-backgroundcolor);
    opacity: 0.9;
    color: var(--button-textcolor);
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    font-size: 10pt;
    gap: 5px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.searchButton:hover {
    opacity: 1;
    background-color: var(--button-bordercolor);
}

.labelMonths {
    font-size: 10pt;
    font-weight: bold;
    color: var(--placeholder-color);
    text-align: center;
    margin: 0;
    height: 20px;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.costsNotification {
    position: absolute;
    left: 0;
    top: 0;
    padding: 4px 8px;
    background-color: #ff4f5a;
    z-index: 17;
    margin-top: 15px;
    font-size: 11px;
    border-radius: 100%;
    color: #f1f1f1;
}

.modalBody {
    background-color: #00000065;
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 18;
}

.backgroundModal {
    height: 50vh;
    width: 40vw;
    background-color: var(--background-content);
    padding: 20px;
    border-radius: 4px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.btnsModal {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    }
  
  .btnsModal button {
    border-radius: 6px;
    cursor: pointer;
  }
  
  .btnsModal button:nth-child(1) {
    border: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--text-primary);
    padding: 0 2rem;
    text-underline-offset: 5px;
  }
  
  .btnsModal button:nth-child(2) {
    padding: 0.75rem 1.5rem;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    transition: 300ms ease-in-out;
  }
  
  .btnsModal button:nth-child(2):hover, .btnsModal button:nth-child(2):focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
  }

  .costWrapper {
    height: 40vh;
    overflow-y: scroll;
    padding: 0 10px;
  }

  .costContainer {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    margin: 5px;
    text-align: center;
  }
  .costContainer h4{
    text-transform: uppercase;
    margin: 10px 0;
    margin-bottom: 15px;
  }

  .costText {
    font-size: 12pt;
    margin: 5px 0;
  }

  .costInput {
    text-decoration: none;
    border: 0;
    outline: 0;
    padding: 10px;
    background-color: var(--background);
    border-radius: 4px;
  }

  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
    gap: 5px;
    border-radius: 4px;
    padding: 0 100px;
}

.noDataText {
    color: #8f8f8f;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    font-size: 12pt;
    text-transform: uppercase;
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
    padding: 0 10px 10px 10px;
    user-select: none;
    background-color: var(--background-content);
    transition: background-color .5s;
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid var(--topbar-bordercolor);
}

.status {
    color: #888;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 5px;
    font-size: 12pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
}

.activeStatus {
    color: var(--text-primary);
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 8px;
    font-size: 12pt;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align:center;
    border-bottom: 2px solid transparent;
    transition: all 300ms;
    border: 2px solid var(--text-primary);
    border-radius: 6px;
    background-color: var(--background);
}

@media only screen and (max-width: 1436px) {
    .graphs {
        display: flex;
        flex-direction: column !important;
        width: 100%;
    }
}

.header {
    position: relative;
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 1256px) {
    .costs {
        top: 0;
        position: relative;
    }
    .costsNotification {
        border-top-left-radius: 4px;
        margin-top: 0;
    }
    .header {
        flex-direction: column-reverse !important;
        margin-bottom: 10px;
    }
}