.content {
  height: calc(100vh - 2rem - 85px);
  background-color: var(--background);
  transition: background-color .5s;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-name: fadein;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.goBack {
  position: fixed;
  margin: 1rem;
  left: 280px;
  top: 45vh;
}
  
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 40vw;
  padding: 20px;
  gap: 15px;
  margin-top: 1rem;
  transition: all 0.3s;
}

.formField {
  display: flex;
  transition: all 0.3s;
}

.inputLabel {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
}

.inputLabelNull {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
  animation: piscar 2s ease-in-out infinite;
}

.formField input {
  font-family: 'Lato';
  width: 100%;
  padding: 13px 16px;
  background-color: var(--input-background);
  color: var(--text-primary);
  font-size: 12pt;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: 0;
  border: 1px solid var(--input-border);
  margin: 0;
  resize: vertical;
}

.formField input::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

@keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

@media only screen and (max-width: 900px) {
  .goBack {
    transition: all .5s;
    left: 3.5rem;
  }
  .form {
    width: 70vw;
  }
  .inputLabel {
    min-width: 100px;
    word-break: break-all;
  }
  .inputLabelNull {
    min-width: 100px;
    word-break: break-all;
  }
}

.submitBtn {
  background-color: var(--button-backgroundcolor);
  color: var(--button-textcolor);
  border: 3px solid var(--button-bordercolor);
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  font-weight: bold;

  background: linear-gradient(to left, var(--button-backgroundcolor) 50%, var(--button-bordercolor) 50%) right;
  background-size: 200%;
  transition: 400ms ease-out;
}
  
  
.submitBtn:hover, .submitBtn:focus {
  background-position: left;
  cursor: pointer;
}