.content {
    height: calc(100vh - 2rem - 100px);
    padding-top: 20px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.titleIcon {
    display: flex;
}

.dutyTitle {
    margin: 0;
    font-size: 16pt;
    text-align: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.chooseReport {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 12px 14px;
    background-color: var(--background-content);
    border: 1px solid var(--background-content);
    transition: all 0.3s ease-in-out;
}

.chooseReportName {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.chooseReport:hover {
    background-color: var(--listitem-hover);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.addAndGrid {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
}

.gridReports {
    display: grid;
    grid-template-columns: repeat(4, 16vw);
    gap: 20px;
}

.selectedReport {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 12px 14px;
    background-color: var(--listitem-hover);
    cursor: pointer;
    border: 1px solid var(--topbar-textcolor);
    transition: all 0.3s ease-in-out;
}

.selectedReport h5 {
    margin: 0;
}

.icons {
    display: flex;
    gap: 3px;
    border-left: 1px solid var(--topbar-textcolor);
    padding-left: 10px;
}

.edit:hover {
    color: var(--graph-text);
}

.delete:hover {
    color: var(--graph-text);
}

.gridFields {
    display: grid;
    grid-template-columns: repeat(3, calc(calc(64vw / 3) + calc(20px / 3)));
    gap: 20px;
    margin: 20px 0;
}

.pForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--input-border);
    border: 1px solid var(--input-border);
    border-radius: 4px;
}

.inputLabel {
    font-size: 11pt;
    margin: 0;
    padding: 6px 0;
}
.inputLabelNull {
    font-size: 11pt;
    margin: 0;
    padding: 6px 0;
}

.input {
    width: calc(100% - 20px);
    padding: 12px 10px;
    outline: none;
    border: 0;
    background-color: var(--background);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
}
.inputNull {
    width: calc(100% - 20px);
    padding: 12px 10px;
    outline: none;
    border: 0;
    background-color: var(--background);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
}

.input:disabled {
    background-color: var(--input-background);
}

.inputNull:disabled {
    background-color: var(--input-background);
}

.inputWithSuffix {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.inputSuffix {
    width: calc(90% - 20px);
    padding: 12px 10px;
    outline: none;
    border: 0;
    background-color: var(--background);
    border-bottom-left-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
}

.inputSuffixNull {
    width: calc(90% - 20px);
    padding: 12px 10px;
    outline: none;
    border: 0;
    background-color: var(--input-background);
    border-bottom-left-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
}

.suffix {
    align-self: center;
    text-align: center;
    width: 10%;
    font-size: 10pt;
    margin: 0;
    padding: 12px 0;
    outline: none;
    border: 0;
    background-color: var(--background);
    border-bottom-right-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
    cursor: default;
}

.suffixNull {
    align-self: center;
    text-align: center;
    width: 10%;
    font-size: 10pt;
    margin: 0;
    padding: 12px 0;
    outline: none;
    border: 0;
    background-color: var(--input-background);
    border-bottom-right-radius: 4px;
    color: var(--text-primary);
    caret-color: var(--text-primary);
    cursor: default;
}

.areaName {
    text-transform: uppercase;
    font-size: 10pt;
    background-color: var(--input-border);
    padding: 8px 0;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0;
    margin-top: 20px;
}

.dutyName {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 11pt;
}

.dutyFormGrid {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
}

.dutyWholeContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: var(--background-content);
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.inputDuty {
    font-size: 11pt;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px;
    background-color: var(--redirectbtn-backgroundcolor);
    margin: 0;
}

.checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background-color: var(--input-background);
    border-radius: 4px;
    border: 1px solid #888;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.checkboxTrue {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background-color: #21ba4520;
    border-radius: 4px;
    border: 1px solid #21ba45;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.checkboxFalse {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background-color: #ff4f5a20;
    border-radius: 4px;
    border: 1px solid #ff4f5a;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.noData {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    padding: 20px 100px;
}

.noDataText {
    color: #8f8f8f;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    font-size: 12pt;
}

.complaintsInput {
    width: calc(64vw + 20px);
    height: auto;
    resize: none;
    border: 1px solid var(--background-content);
    outline: none;
    margin: 0;
    padding: 10px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: "Lato";
    font-weight: normal;
    background-color: var(--input-background);
    white-space: pre-wrap;
    font-size: 12pt;
}

.submitBtn {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    margin-bottom: 20px;
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    transition: 300ms ease-out;
  }
  
  
.submitBtn:hover, .submitBtn:focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
}

.addDuty {
    gap: 5px;
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 250ms;
    text-decoration: none;
    color: var(--background);
    font-weight: bolder;
    font-size: 12pt;
    text-transform: uppercase;
    cursor: pointer;
}

.addIcon {
    color: var(--background);
}

.addDuty:hover {
    opacity: 1;
}

.buttons {
    display: grid;
    grid-template-columns: repeat(2, calc( calc(64vw + 51px) / 2));
    gap: 10px;
}

.areasContainer {
    max-width: calc(64vw + 61px);
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-name: popin;
}

.modalBody {
    background-color: #00000065;
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 18;
}

.backgroundModal {
    height: 50vh;
    width: 40vw;
    background-color: var(--background-content);
    padding: 20px;
    border-radius: 4px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.btnsModal {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
  
  .btnsModal button {
    border-radius: 6px;
    cursor: pointer;
  }
  
  .btnsModal button:nth-child(1) {
    padding: 0.75rem 1.5rem;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    transition: 300ms ease-in-out;
  }
  
  .btnsModal button:nth-child(1):hover, .btnsModal button:nth-child(1):focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
  }

  .recordWrapper {
    height: 40vh;
    overflow-y: scroll;
    padding: 0 10px;
  }

  .recordContainer {
    display: flex;
    flex-direction: column;
    margin: 5px;
    text-align: center;
  }
  .recordContainer h4{
    text-transform: uppercase;
    margin: 10px 0;
    margin-bottom: 15px;
  }

  .recordContainer2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .recordText {
    margin: 0;
    font-size: 12pt;
  }

@keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media only screen and (max-width: 1184px) {
    .gridReports {
        display: flex;
        flex-direction: column !important;
        min-width: 50vw;
    }

}

@media only screen and (max-width: 1053px) {
    .gridFields {
        display: flex;
        flex-direction: column !important;
        align-items: center;
    }
    .pForm {
        width: 50vw;
    }
    .dutyFormGrid {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        gap: 10px;
    }
    .dutyWholeContainer {
        width: 50vw;
    }
    .inputDuty {
        width: 80%;
    }
    .areaName {
        width: 50vw;
        padding: 10px;
    }
    .complaintsInput {
        width: 50vw;
        padding: 10px;
    }
}