.body {
    background-color: #00000065;
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
  }
  
  .hide {
    display: none;
  }
  
  .box {
    background-color: var(--background);
    max-width: 450px;
    max-height: 60vh;
    overflow: hidden;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    animation: box-animation 0.2s ease-out forwards;
  }
  
  @keyframes box-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .content {
    max-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    gap: 1rem;
  }
  
  .btns {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
  
  .box p {
    margin: 0;
  }
  
  .btns button {
    border-radius: 6px;
    cursor: pointer;
  }
  
  .btns button:nth-child(1) {
    border: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--text-primary);
    padding: 0 2rem;
    text-underline-offset: 5px;
  }
  
  .btns button:nth-child(2) {
    padding: 0.75rem 1.5rem;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    transition: 300ms ease-in-out;
  }
  
  .btns button:nth-child(2):hover, .btns button:nth-child(2):focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .content input {
    background-color: #f9f9f9;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    padding: 0.75rem;
  }
  
  .content p {
    margin: 0;
    font-size: 12pt;
  }
  
  .header {
    margin-bottom: 1rem;
  }
  
  .header p {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.15rem;
  }
  
  .reason {
    font-family: 'Lato';
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
    animation: none;
  }

  .reasonNull {
    font-family: 'Lato';
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
    animation: piscar 2s ease-in-out infinite;
  }

  @keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

  .regressar {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .regressar button {
    padding: 0.75rem 2rem;
    border: 0;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    transition: 300ms ease-in-out;
    border-radius: 4px;
  }

  .regressar button:hover, .regressar button:focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
  }

  .formField {
    display: flex;
    transition: all 0.3s;
}

.formField input {
  font-family: 'Lato';
  width: 100%;
  padding: 13px 16px;
  background-color: var(--input-background);
  color: var(--text-primary);
  font-size: 12pt;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: 0;
  border: 1px solid var(--input-border);
  margin: 0;
  resize: vertical;
}

.formField input::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.inputLabel {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
}

.inputLabelNull {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
  animation: piscar 2s ease-in-out infinite;
}