.content {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content h1 {
  font-size: 54pt;
  margin-bottom: 0;
  user-select: none;
}

.slogan {
  margin-top: 0;
  margin-bottom: 40px;
  user-select: none;
}

.label {
  display: flex;
  align-items: center;
  background-color: var(--input-background);
  font-size: 12pt;
  margin: 10px 0;
  border-radius: 4px;
  width: 340px;
  border: 1px solid var(--input-border);
}

.label input {
  width: 340px;
  background-color: var(--input-background);
  color: var(--text-primary);
  padding: 16px 13px;
  font-size: 12pt;
  border-radius: 4px;
  border: 0;
  outline: 0;
}

.label input::placeholder {
  color: var(--placeholder-color);
}

.eyeIcon {
  color: var(--placeholder-color);
  margin-right: 5px;
  cursor: pointer;
}

.centerBtn {
  display: flex;
  justify-content: center;
}

.btnAdd {
  position: relative;	
  background-color: var(--button-backgroundcolor);
  color: var(--button-textcolor);
  border: 3px solid var(--button-bordercolor);
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  font-weight: bold;
  transition: 300ms ease-out;
}


.btnAdd:hover, .btnAdd:focus {
  background-color: var(--button-bordercolor);
  cursor: pointer;
}

.gear {
  margin-top: 5px;
  animation: rotate 5s linear infinite
}

.timer {
  font-size: 10pt;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--graph-text);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}