.background {
    width: 100vw;
    height: 100vh;
    background-color: #f0f0f050;
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    backdrop-filter: blur(1px);
  }

  .background p {
    margin-top: 0;
  }