.navbar {
  display: flex;
  flex-direction: column;
  background-color: var(--navbar-backgroundcolor);
  padding: 0 5px;
  height: 100vh;
  position: fixed;
  width: 251px;
  align-items: center;
  transition: background-color .5s;
}

.fadeScroll {
  content: "😊";
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, var(--navbar-backgroundcolor) 60%, transparent 100%);
  height: 10%;
}

.scrollNav {
  width: calc(100% - 5px);
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
}

.scrollNav::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 900px) {
  .navbar {
    padding-top: 10px;
    width: 4rem;
  }
  .logo {
    display: none !important;
  }
  .tabIconText h3{
    display: none;
  }
  .date {
    display: none !important;
  }
  .opacityContainerLeft {
    display: none !important;
  }
  .opacityContainerRight {
    display: none !important;
  }
  .userNames {
    display: none !important;
  }
  .buttons {
    width: 100% !important;
    flex-direction: column !important;
    margin: 0 !important;
  }
  .threeDots {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    width: 100%;
    padding: 9px 0 !important;
  }
  .ulHeader {
    padding: 0.5rem 1rem !important;
  }
  .ulHeaderActive {
    padding: 0.5rem 1rem !important;
  }
  .orderHotelNameLogout {
    position: relative;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px;
  }
  .hotel label {
    display: none !important;
  }
  .buttons button {
    width: 100% !important;
  }
  .buttons button label {
    display: none !important;
  }
  .boxUser {
    align-items: center !important;
    justify-content: center !important;
    padding: 1rem 0 !important;
    margin-bottom: 100px !important;
  }
  .actionsModal {
    margin: 0 !important;
    margin-top: 174px !important;
    left: 5px !important;
  }
  .actionsModalOption label {
    display: none !important;
  }
  .ulBody {
    font-size: 10pt !important;
    word-break: break-all;
    padding: 0.5rem 0 !important;
  }
  .ulBodyActive {
    font-size: 10pt !important;
    word-break: break-all;
    padding: 0.5rem 0 !important;
  }
}

.orderHotelNameLogout {
  display: flex;
  align-items: flex-end;
}

.ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: min-content;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  background-color: var(--navbar-backgroundcolor);
}

.ulHeader {
  width: 100%;
  margin: 3px 0;
  text-decoration: none;
  border-radius: 6px;
  color: var(--button-textcolor);
  border: 0;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  transition: all 0.2s ease-in-out;
  background-color: var(--navbar-itemcolor);
  cursor: pointer;
  letter-spacing: 0.8px;
  border: 2px solid var(--navbar-itemcolor);
}

.rotatedArrowIn {
  animation: rotatingIn 300ms ease-in-out;
  transform: rotate(180deg);
}

@keyframes rotatingIn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.rotatedArrowOut {
  transform: rotate(0deg);
  transition: 300ms;
}

.ulBody {
  margin: 3px;
  text-decoration: none;
  border-radius: 6px;
  color: var(--button-textcolor);
  font-weight: 100;
  letter-spacing: 0.8px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-right: 0;
  position: relative;
  transition: 0.2s;
  background-color: var(--navbar-backgroundcolor);
  cursor: pointer;
  font-size: 12pt;
}

.ulBody:hover {
  background-color: var(--ulbodyactive-backgroundcolor);
}

.ulBodyActive {
  margin: 3px;
  text-decoration: none;
  border-radius: 6px;
  color: var(--graph-text);
  letter-spacing: 0.8px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-right: 0;
  position: relative;
  transition: 0.2s;
  background-color: var(--ulbodyactive-backgroundcolor);
  cursor: pointer;
  font-size: 12pt;
}

.navlink {
  text-decoration: none;
}

.ulHeaderActive {
  width: 100%;
  margin: 3px 0;
  text-decoration: none;
  border-radius: 6px;
  color: var(--button-textcolor);
  border: 0;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  transition: 0.2s;
  background-color: var(--ulheaderactive-backgroundcolor);
  border: 2px solid var(--button-bordercolor);
  cursor: pointer;
  letter-spacing: 0.8px;
}

.ulHeaderActive:hover {
  background-color: var(--ulheaderactive-backgroundcolor);
}

.ulHeader:hover,
.active {
  color: #fff !important;
  background-color: var(--ulheaderactive-backgroundcolor);
  border: 2px solid var(--button-bordercolor);
}

.ulHeader h3 {
  text-transform: uppercase;
  font-family: 'Lato';
  letter-spacing: 1.2px;
  font-size: 11pt;
}

.ulHeaderActive h3 {
  font-family: 'Lato';
  letter-spacing: 1.2px;
  font-size: 11pt;
  text-transform: uppercase;
}

.boxUser {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--navbar-itemcolor);
  color: var(--button-textcolor);
  padding: 20px 0;
  margin: 10px 0;
  margin-bottom: 80px;
  user-select: none;
  overflow: hidden;
}

.date {
  font-weight: normal;
  font-size: 14pt;
  letter-spacing: 0.2px;
  color: var(--placeholder-color);
  margin: 0 20px;
  font-family: 'Lato';
}
.tabIconText {
  display: flex;
  gap: 15px;
}

.userNames {
  display: inline-block;
  white-space: nowrap;
  max-width: 205px;
  margin: 0 20px;
  overflow-x: hidden;
  font-size: 18pt;
  font-family: 'Lato';
  font-weight: normal;
  letter-spacing: 0.2px;
}

.opacityContainerRight {
  right: 0;
  position: absolute;
  background: rgb(72,67,88);
  background: linear-gradient(90deg, transparent 0%, var(--navbar-itemcolor) 82%); 
  height: 80px;
  width: 40px;
  z-index: 100;
}

.opacityContainerLeft {
  left: 0;
  position: absolute;
  background: rgb(72,67,88);
  background: linear-gradient(90deg, var(--navbar-itemcolor) 22%, transparent 100%); 
  height: 80px;
  max-width: 20px;
  z-index: 100;
}


.boxUser button {
  position: relative;	
  background-color: var(--navbar-backgroundcolor);
  color: var(--button-textcolor);
  border: 0;
  padding: 10px 10px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.buttons {
  display: flex;
  gap: 5px;
  margin: 0 15px;
  margin-top: 10px;
  margin-right: 0;
}

.hotel {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: var(--navbar-backgroundcolor);
  border-radius: 4px;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  user-select: none;
  color: var(--button-textcolor);
}

.logo h1{
  margin: 0;
  margin-top: 20px;
}
.logo p{
  margin: 0;
  margin-bottom: 20px;
  color: #aaa;
  font-size: 12pt;
}

.bottom {
  padding: 2rem 0;
}

.bottom p {
  color: #fff;
}

.column {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  color: #041e42;
}

.column span {
  color: #fff;
}

.notification {
  color: #fff;
  background-color: #ff4f5a;
  border-radius: 50px;
  text-align: center;
  display: grid;
  place-items: center;
  padding: 0px 6px;
  height: 20px;
  position: absolute;
  font-size: 0.85rem;
  right: 1rem;
  margin: 0;
}

.formFieldPass {
  display: flex;
  transition: all 0.3s;
}

.inputFormField {
  font-family: 'Lato';
  width: 100%;
  padding: 13px 16px;
  background-color: var(--input-background);
  color: var(--text-primary);
  font-size: 12pt;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: 0;
  margin: 0;
  resize: vertical;
}

.inputFormField input::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.inputLabel {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
}

.inputLabelNull {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12pt;
  letter-spacing: 0.8px;
  border-top: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-left: 1px solid var(--input-border);
  background-color: var(--input-background);
  color: var(--placeholder-color);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-width: 140px;
  padding: 8px 0;
  text-align: center;
  animation: piscar 2s ease-in-out infinite;
}

.actionsModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 5px;
  left: 15px;
  background-color: var(--background);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  margin-top: 100px;
}

.actionsModalOption {
  padding: 8px 10px;
  font-size: 12pt;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: var(--text-primary);
}
.actionsModalOption:hover {
  background-color: var(--background-content);
  cursor: pointer;
}

.threeDots {
  display: flex;
  align-items: center;
  background-color: var(--navbar-backgroundcolor);
  border-radius: 4px;
  padding: 9px 6px;
  margin-left: 5px;
  margin-top: 10px;
}

.logo h1:hover .gearLogo{
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}