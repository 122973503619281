.content {
    height: calc(100vh - 2rem - 105px);
    padding-bottom: 5px;
    padding-top: 20px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.redirectBtn {
    width: calc(20vw - 20px);
    position: relative;	
    background-color: var(--redirectbtn-backgroundcolor);
    border: 3px solid var(--redirectbtn-bordercolor);
    color: var(--redirectbtn-textcolor);
    padding: 20px 0;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    
    background: linear-gradient(to left, var(--redirectbtn-backgroundcolor) 50%, var(--redirectbtn-hoverbackground) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
}

.redirectBtn:hover, .redirectBtn:focus {
    background-position: left;
    cursor: pointer;
}

.tocaAccordion {
    display: grid;
    grid-template-columns: 40vw;
    gap: 20px;
}

.ulHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;	
    background-color: var(--redirectbtn-backgroundcolor);
    border: 1px solid var(--redirectbtn-bordercolor);
    color: var(--redirectbtn-textcolor);
    padding: 0 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.ulHeader:hover  {
    background-color: var(--redirectbtn-hoverbackground);
}

.ulBody {
    border-bottom: 3px solid var(--redirectbtn-bordercolor);
    border-left: 3px solid var(--redirectbtn-bordercolor);
    border-right: 3px solid var(--redirectbtn-bordercolor);
}

.dropdown {
    margin-left: 20px;
    width: 45vw;
}

.localTitle {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14pt;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.localsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin: 15px 0;
    background-color: var(--input-background);
    border-radius: 6px;
}
.localsContainer p {
    margin: 14px 0;
    font-size: 12pt;
}

.localsActions {
    display: flex;
    gap: 5px;
}

.hoverAdd:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.hoverEdit:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.hoverDelete:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.dropdownActions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.changePages {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.search {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background-color: var(--background-content);
    border: 1px solid var(--topbar-bordercolor);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 10px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: var(--text-primary);
}

input::placeholder {
    color: var(--placeholder-color);
}

.addLocal {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 8px 12px;
    transition: all 250ms;
    cursor: pointer;
}

.addIcon {
    color: var(--background);
}

.addLocal:hover {
    opacity: 1;
}