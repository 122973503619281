.content {
    height: calc(100vh - 2rem - 100px);
    padding-top: 20px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.titleIcon {
    display: flex;
}

.linenCountTitle {
    margin: 0;
    font-size: 16pt;
    text-align: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.addGroup {
    gap: 5px;
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 250ms;
    text-decoration: none;
    color: var(--background);
    font-weight: bolder;
    font-size: 12pt;
    text-transform: uppercase;
    cursor: pointer;
    margin: 10px 0;
}

.addIcon {
    color: var(--background);
}

.addGroup:hover {
    opacity: 1;
}

.gridAdicionarButton {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.gridGroups {
    display: grid;
    width: calc(100%);
    grid-template-columns: repeat(4, calc(calc(100% / 4) - 20px));
    gap: 20px;
}

.chooseGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    gap: 20px;
    padding: 12px 14px;
    background-color: var(--background-content);
    border: 1px solid var(--background-content);
    transition: all 0.3s ease-in-out;
}

.chooseGroup:hover {
    background-color: var(--listitem-hover);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.chooseGroupName {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.chooseType {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border: 1px solid var(--topbar-textcolor);
    padding: 10px;
    border-radius: 4px;
}

.chooseTypeName {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15pt;
}

.otherTypeFields {
    margin: 0;
    font-size: 9pt;
}

.typesQuantity {
    display: flex;
    align-items: center;
}

.input {
    text-align: center;
    font-family: 'Lato';
    width: 30px;
    padding: 12px 8px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}


.selectedGroup {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-radius: 4px;
    padding: 12px 14px;
    background-color: var(--listitem-hover);
    cursor: pointer;
    border: 1px solid var(--topbar-textcolor);
    transition: all 0.3s ease-in-out;
}

.selectedGroup h5 {
    margin: 0;
}

.icons {
    display: flex;
    gap: 3px;
    border-left: 1px solid var(--topbar-textcolor);
    padding-left: 10px;
}

.edit:hover {
    color: var(--graph-text);
}

.delete:hover {
    color: var(--graph-text);
}

.changePages {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
    width: 40vw;
    padding: 20px;
    gap: 15px;
    margin-top: 3rem;
}

.formField {
    display: flex;
    gap: 10px;
    margin: 10px 20px;
}

.inputLabel {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.inputLabelNull {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

.formField input {
    font-family: 'Lato';
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.formField input::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
}

.submitBtn {
    background-color: var(--background);
    color: var(--text-primary);
    border: 3px solid var(--button-bordercolor);
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    transition: 300ms ease-out;
  }
  
  
.submitBtn:hover, .submitBtn:focus {
    cursor: pointer;
    background-color: var(--button-bordercolor);
    color: #f5f5f5;
}

.submitBtnActive {
    background-color: var(--button-bordercolor);
    color: #f5f5f5;
    border: 3px solid var(--button-bordercolor);
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    transition: 300ms ease-out;
}

.options {
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
}

.countings {
    width: calc(100% - 60px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px;
}

.countedLinen {
    background-color: var(--background-content);
    min-width: calc(calc(100% - 190px) / 4);
    display: flex;
    flex-direction: column;
    padding: 1rem 16px;
    font-size: 12pt;
    border-radius: 4px;
}

.dano1 {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--background-content);
}
.dano1 {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 15px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--background-content);
}
.dano2 {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 15px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--background-content);
}
.dano3 {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 15px;
    height: 10px;
    border-radius: 100%;
    background-color: #74664d34;
}
.dano4 {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 15px;
    height: 10px;
    border-radius: 100%;
    background-color: #74664d34;
}

.counts {
    display: flex;
    flex-direction: column;
}

.linenSections p {
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: var(--background);
    padding: 8px;
    margin: 0;
    border-radius: 4px;
    text-align: center;
    font-size: 13pt;
}

.linenTypeName {
    margin: 0;
    text-align: center;
    font-size: 15pt;
    font-weight: bold;
}

.linenSections {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}
.linenSectionsHeader {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    font-size: 10pt;
}

.linenSectionsHeader p {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    min-width: 70px;
}

@media only screen and (max-width: 1110px) {
    .gridGroups {
        display: flex;
        width: calc(100% - 40px);
        flex-wrap: wrap;
    }
}