.content {
    min-height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
}

@media only screen and (max-width: 900px) {
    .description {
      word-break: break-all;
    }
}

.content h1 {
    margin: 0;
}

.searchBar {
    display: flex;
    justify-content: space-between;
    transition: background-color .5s;
    background-color: var(--background-content);
    padding-bottom: 6px;
    margin-bottom: 10px;
}

.requestsStatus {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    width: fit-content;
    text-transform: uppercase;
    margin: 0 10px;
    user-select: none;
}

.status {
    color: #888;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 5px;
    font-size: 12pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
}

.search {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px 0;
    background-color: var(--background);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 5px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: var(--background);
    color: var(--text-primary);
}

.search input:focus + .search{
    border: 1px solid var(--topbar-bordercolor);
}

::placeholder {
    color: var(--topbar-textcolor);
    opacity: 1;
}

.searchIcon {
    color: var(--topbar-textcolor);
}

.searchIconActive {
    color: var(--text-primary)
}

.activeStatus {
    color: var(--text-primary);
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 8px;
    font-size: 12pt;
    border-bottom: 3px solid var(--text-primary);
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align:center;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, transparent, var(--text-primary), transparent);
    border-image-slice: 1;
    transition: all 300ms;
}

.addRequest {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 4px 10px;
    transition: all 250ms;
    cursor: pointer;
}

.fade {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
}
  
  @keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.addIcon {
    color: var(--background);
}

.addRequest:hover {
    opacity: 1;
}

.requestContainer {
    font-weight: 600;
    text-decoration: none;
    color: var(--text-primary);
    transition: background-color .5s;
    margin: 10px;
    padding: 15px 5px;
    background-color: var(--background-content);
    border-radius: 6px;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    cursor: pointer;
}

.requestContainer:hover {
    background-color: var(--listitem-hover);
}

.requestSection {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.requestSection p {
    margin: 10px 10px 0 44px;
    margin-bottom: 0;
    color: #888;
    font-size: 12pt;
}

.iconArea {
    display: flex;
}

.requestInfo h4 {
    margin: 0;
}
.requestDetails {
    position: relative;
    width: 100%;
    margin: 0 5px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.requestDetails p{
    margin: 0;
}

.actions {
    align-self: center;
    margin: 0 20px;
    cursor: default;
    display: flex;
}

.actions img {
    padding: 10px;
}

.actionsModal {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    right: 40px;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    z-index: 2;
}

.actionsModalOption {
    padding: 8px 10px;
    font-size: 12pt;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: var(--text-primary);
}
.actionsModalOption:hover {
    background-color: var(--background-content);
    cursor: pointer;
}

.pencilBorder {
    display: grid;
    place-items: center;
    width: 15px;
    height: 15px;
    border: 1px solid #529eff;
    border-radius: 100%;
    margin: 2px;
}

.priority {
    padding: 3px 6px;
    width: fit-content;
    height: fit-content;
    font-size: 10pt;
    color: var(--background);
    border-radius: 3px;
    letter-spacing: 0.3px;
    font-weight: bold;
}

.requestInfo {
    width: 90%;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.requestInfo p {
    font-size: 11pt;
    margin: 0;
}

.createdbyAndDate {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: #888;
    letter-spacing: 0.2px;
}

.description {
    font-size: 14px;
    color: #888;
    border-radius: 4px;
    padding: 5px;
    margin-left: 44px;
    background-color: var(--background);
    letter-spacing: 0.3px;
    height: fit-content;
    width: 100%;
}

.changePages {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 5px;
    border-radius: 4px;
    padding: 0 100px;
}

.noDataText {
    color: #8f8f8f;
    padding: 0;
    margin: 0;
    margin-right: 20px;
    font-size: 12pt;
    text-transform: uppercase;
}

.settingsHousekeeping {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: transparent;
    opacity: 0.85;
    border-radius: 5px;
    padding: 4px 6px;
    transition: all 250ms;
    cursor: pointer;
}

.checked {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 9pt;
    margin: 0;
    gap: 5px;
    position: sticky;
    color: var(--placeholder-color);
    font-weight: bold;
    cursor: default;
    width: fit-content;
}