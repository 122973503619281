.body {
    background-color: var(--background);
    height: 100vh;
}

.center {
  display: grid;
  place-items: center;
  height: 90vh;
}

.center h1 {
  font-size: 5rem;
  margin: 0;
  opacity: 0;
  animation: other 5s .1s forwards;
}

.center p {
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 10px;
  opacity: 0;
  animation: other 5s .2s forwards;
}

.column {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.center a {
    text-decoration: underline;
    color: black;
}

.gearLogo {
    width: 100px;
    animation: image 5s infinite;
}

.circle {
    background-color: var(--button-backgroundcolor);
    border: 1px solid var(--button-bordercolor);
    align-items: center;
    justify-content: center;
    display: flex;
    padding: .5rem 1rem;
    border-radius: 6px;
    transition: .2s;
    opacity: 0;
    animation: other 5s .3s forwards;
}

.circle:hover {
    box-shadow: 0px 0px 5px 0px var(--button-bordercolor);
    cursor: pointer;
}

@keyframes other {
   0% {
    opacity: 0;
    transform: translateY(2rem);
   }
   10% {
    opacity: 1;
    transform: translateY(0);
   }
   15% {
    opacity: 1;
    transform: translateY(0);
   }
   85%{
    opacity: 1;
    transform: translateY(0);
   }
   90% {
    opacity: 1;
    transform: translateY(0);
   }
   100%{
    opacity: 1;
    transform: translateY(0);
   }
}

@keyframes image {
   0% {
    opacity: 0;
    transform: translateY(2rem);
   }
   10% {
    opacity: 1;
    transform: translateY(0) rotate(0.1turn);
   }
   15% {
    opacity: 1;
    transform: translateY(0) rotate(0.15turn);
   }
   85%{
    opacity: 1;
    transform: translateY(0) rotate(0.85turn);
   }
   90% {
    opacity: 1;
    transform: translateY(.3rem) rotate(0.9turn);
   }
   100%{
    opacity: 0;
    transform: translateY(-1rem) rotate(1turn);
   }
}
