.App {
  color: var(--text-primary);
  background-color: var(--background-content);
  font-size: large;
  height: calc(100vh);
  transition: all .5s;
  overflow: hidden;
}

.App-logo {
  max-height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.switchMode {
  color: var(--text-primary);
  background-color: transparent;
  float: right;
  transition: background-color .5s;
  border: 0;
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 20px;
}

.switchMode:hover {
  cursor: pointer;
}