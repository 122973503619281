.bodyMulti {
    background-color: #00000065;
    backdrop-filter: blur(2px);
    display: flex;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 3;
  }
.form {
    display: flex;
    flex-direction: column;
}

.dropdown {
    display: flex;
}

.dropdownNull {
    display: flex;
}

.dropdownNull p {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

.dropdownLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Lato';
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.dropdown p {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.containerItems {
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    padding: 10px 0;
    overflow-y: scroll;
    max-height: 50vh;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 5px 0;
    background-color: var(--input-background);
    box-shadow: 0 3px 7px var(--boxshadow);
    border-radius: 6px;
    animation: rotateMenu 400ms ease-in-out forwards;
    text-align: center;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 263px;
}

.confirmBtn {
    margin: 10px;
    padding: 10px 18px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 4px;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-backgroundcolor);
    color: var(--button-textcolor);
    transition: 300ms ease-in-out;
  }

  .confirmBtn:hover, .confirmBtn:focus {
    background-color: var(--button-backgroundcolor);
    cursor: pointer;
  }

.title {
    text-align: center;
    text-transform: uppercase;
}

@keyframes rotateMenu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 900px) {
    .dropdown p {
        min-width: 100px;
        word-break: break-all;
    }
    .dropdownNull p {
        min-width: 100px;
        word-break: break-all;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    margin: 0 5px;
    padding: 8px 12px;
    font-size: 13pt;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    user-select: none;
}
.item p {
    margin: 0;
}
.item:hover {
    background-color: var(--listitem-hover);
    cursor: pointer;
}
.item label {
    font-size: 10px;
}
.checkSquare {
    height: 20px;
    width: 20px;
    border: 2px solid var(--text-primary);
    border-radius: 4px;
}
.chosenItem {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    margin: 0 5px;
    padding: 8px 12px;
    font-size: 13pt;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}
.chosenItem:hover {
    background-color: var(--listitem-hover);
}
.chosenItem label {
    font-size: 10px;
}
.chosenItem p {
    margin: 0;
}
.checkedSquare {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: var(--text-primary);
    border: 2px solid var(--text-primary);
    border-radius: 4px;
}

.search {
    align-self: center;
    width: 90%;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px 0;
    background-color: var(--background);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 5px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: var(--background);
    color: var(--text-primary);
}

.search input:focus + .search{
    border: 1px solid var(--topbar-bordercolor);
}

::placeholder {
    color: var(--topbar-textcolor);
    opacity: 1;
}

.searchIcon {
    color: var(--topbar-textcolor);
}

@keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

  .results {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
  }

  .result {
    gap: 5px;
    display: flex;
    align-items: center;
    background-color: var(--topbar-textcolor);
    font-size: 12pt;
    border-radius: 10px;
    padding: 6px 10px;
    user-select: none;
  }

  .changePages {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}