.divider {
    display: flex;
    background-color: var(--background-content);
    transition: all .5s;
  }
  
  .container {
    width: calc(100% - 250px);
    margin-top: 80px;
    margin-left: 262px;
    background-color: var(--background-content);
    min-height: calc(100vh - 2rem - 105px);
    transition: all .5s;
  }

  .scrollContent {
    background-color: var(--background);
    margin: 1rem;
    height: calc(100vh - 2rem - 80px);
  }
  
  @media only screen and (max-width: 900px) {
    .container {
      margin-left: 4rem;
      width: 100%;
    }
  }
  