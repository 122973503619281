.content {
    height: calc(100vh - 2rem - 105px);
    padding-bottom: 5px;
    padding-top: 20px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.redirectBtn {
    width: calc(20vw - 20px);
    position: relative;	
    background-color: var(--redirectbtn-backgroundcolor);
    border: 3px solid var(--redirectbtn-bordercolor);
    color: var(--redirectbtn-textcolor);
    padding: 20px 0;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    
    background: linear-gradient(to left, var(--redirectbtn-backgroundcolor) 50%, var(--redirectbtn-hoverbackground) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
}

.redirectBtn:hover, .redirectBtn:focus {
    background-position: left;
    cursor: pointer;
}

.ulHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;	
    background-color: var(--redirectbtn-backgroundcolor);
    border: 1px solid var(--redirectbtn-bordercolor);
    color: var(--redirectbtn-textcolor);
    padding: 0 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.ulHeader:hover  {
    background-color: var(--redirectbtn-hoverbackground);
}

.ulBody {
    border-bottom: 3px solid var(--redirectbtn-bordercolor);
    border-left: 3px solid var(--redirectbtn-bordercolor);
    border-right: 3px solid var(--redirectbtn-bordercolor);
}

.form {
    display: flex;
    flex-direction: column;
    width: 40vw;
}

.formBorder {
    border: 1px solid var(--input-border);
    background-color: var(--redirectbtn-hoverbackground);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    padding: 10px;
    gap: 8px;
    margin: 15px 0;
}

.formBorder h4 {
    margin: 5px 0;
    text-transform: uppercase;
    text-align: center;
}

.categoryTitle {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14pt;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.categoryContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin: 15px 0;
    background-color: var(--input-background);
    border-radius: 6px;
}
.categoryContainer p {
    margin: 14px 0;
    font-size: 12pt;
}

.categoryActions {
    display: flex;
    gap: 5px;
}

.hoverAdd {
    text-decoration: none;
}

.hoverAdd:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.hoverEdit:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.hoverDelete:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.dropdownActions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.changePages {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.search {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background-color: var(--background-content);
    border: 1px solid var(--topbar-bordercolor);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 10px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: var(--background-content);
    color: var(--text-primary);
}

input::placeholder {
    color: var(--placeholder-color);
}

.addCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    height: fit-content;
    font-size: 10pt;
    background-color: var(--background);
    border-radius: 100%;
    transition: background-color .5s;
}

.addIcon {
    cursor: pointer;
    transform: scale(1.1);
    color: var(--text-primary);
}

.addIcon:hover {
    transform: scale(1.2);
}

.formField {
    display: flex;
}

.inputLabel {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.inputLabelNull {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

.formField input {
    font-family: 'Lato';
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.formField input::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
}

.addBtn {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    padding: 10px 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
  }
  
.addBtn:hover, .addBtn:focus {
    cursor: pointer;
}

@keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

@media only screen and (max-width: 900px) {
    .goBack {
        transition: all .5s;
        left: 3.5rem;
    }
    .form {
        width: 70vw;
    }
    .dropdownActions {
        display: flex;
        justify-content: center;
    }
    .inputLabel {
        min-width: 100px;
        word-break: break-all;
    }
    .inputLabelNull {
        min-width: 100px;
        word-break: break-all;
    }
}

.subcategory {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: var(--background-content);
    gap: 10px;
    border-radius: 6px;
    font-size: 12pt;
    font-weight: bold;
}

.subcategory p {
    margin: 0;
}

.actionIcons {
    display: flex;
    gap: 5px;
}
.dropdownActions {
    display: flex;
}

.equipmentCategory {
    padding: 0 10px;
    border-radius: 6px;
    font-size: 12pt;
}

.equipmentCategory p {
    margin: 0;
}

.equipmentActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.equipmentName {
    text-decoration: none;
    color: var(--text-primary);
    padding: 8px;
    border-radius: 6px;
    margin: 4px 10px;
    font-size: 12pt;
    display: flex;
    justify-content: space-between;
    background-color: var(--background);
    cursor: pointer;
}

.equipmentName:hover {
    background-color: var(--background-content);
    cursor: pointer;
}

.titleActions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16pt;
    margin-bottom: 10px;
}

.addEquipmentModal {
    margin-top: 50px;
    align-self: end;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background-color: var(--background);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
}

.addEquipmentModalOption {
    padding: 8px 10px;
    font-size: 12pt;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: var(--text-primary);
}
.addEquipmentModalOption:hover {
    background-color: var(--background-content);
    cursor: pointer;
}