.content {
    height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
  
  @keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.onlyTask {
    font-size: 12pt;
}

.goBack {
    position: fixed;
    margin: 1rem;
    left: 280px;
    top: 45vh;
}

.history {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 1rem;
    right: 20px;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .goBack {
        transition: all .5s;
        left: 3.5rem;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn {
        min-width: 100%;
    }
    .containerData {
        display: flex !important;
        flex-direction: column;
    }
    .containerDataRecords {
        display: flex !important;
        flex-direction: column;
    }
    .containerDataLocals {
        display: flex !important;
        flex-direction: column;
    }
    .taskTextNameCheckbox {
        min-width: 100% !important;
    }
    .taskTextNameCheckboxChecked {
        min-width: 100% !important;
    }
}
@media only screen and (max-width: 1105px) {
    .localsContainer {
        display: inline-block !important;
        width: calc(100% - 40px) !important;
    }
}

.malfunctionInfo h4 {
    display: flex;
    justify-content: center;
    font-size: 20pt;
    margin: 0;
    margin-top: 20px;
}

.malfunctionInfo {
    cursor: default;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.malfunctionInfo p {
    margin: 0;
}

.measurementNameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
}

.priority {
    align-self: center;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    font-size: 13pt;
    color: var(--background);
    border-radius: 3px;
    letter-spacing: 0.3px;
    font-weight: bold;
    margin-right: 15px;
}

.description {
    width: 45%;
    margin-top: 20px;
    font-size: 12pt;
    color: var(--text-primary);
    border-radius: 4px;
    padding: 8px 12px;
    background-color: var(--background-content);
    letter-spacing: 0.3px;
    height: fit-content;
    font-weight: bold;
}

.iconsAndLabels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.icons {
    display: flex;
    flex-direction: column;
    font-size: 12pt;
}

.iconLabels {
    display: flex;
    flex-direction: column;
}

.icon {
    height: 35px;
    padding: 5px;
}

.iconsLabels h5 {
    width: fit-content;
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 13pt;
    margin: 0;
    letter-spacing: 0.5px;
    padding: 5px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
}

.btn {
    width: calc(20vw - 20px);
    position: relative;	
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    margin-top: 20px;
    padding: 15px 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
  
    background: linear-gradient(to left, var(--button-backgroundcolor) 50%, var(--button-bordercolor) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
}
.btnSave {
    width: fit-content;
    align-self: center;
    position: relative;	
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    margin-bottom: 20px;
    padding: 15px 100px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.btnSave:hover, .btnSave:focus {
    background-color: var(--button-bordercolor);
    transition: 200ms ease-in-out;
    animation: ease-in-out;
}

.btn:hover, .btn:focus {
    background-position: left;
    cursor: pointer;
}

.malfunctionImg {
    object-fit: contain;
    max-width: 40vw;
    max-height: 60vh;
}

.malfunctionImages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
}


/* logs */

.logs {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 80%;
}

.log {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.log p {
    margin: 3px;
}

.reason {
    margin-top: 10px;
    border-radius: 6px;
    padding: 5px 10px;
    width: fit-content;
    background-color: var(--topbar-textcolor);
}

.fotoIcon {
    cursor: pointer;
}

.infoAndDescription {
    display: flex;
    justify-content: space-between;
}

.containerData h5{
    font-size: 13pt;
    text-align: center;
}

.localsStatus1 {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--background-content);
    border-radius: 8px;
    padding: 20px;
    margin: 0;
    margin-bottom: 20px;
}

.doneTasks {
    font-size: 10pt;
    color: #888;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    gap: 5px;
}

.doneTasks100 {
    font-size: 10pt;
    color: #21ba45;
    background-color: #21ba4520;
    padding: 5px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.localsStatus2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background-color: var(--background-content);
    border: 1px solid var(--background-content);
    border-radius: 8px;
    padding: 20px;
    margin: 0;
    cursor: pointer;
}

.localsStatus2:hover {
    background-color: var(--listitem-hover);
}

.selectedLocal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background-color: var(--listitem-hover);
    border: 1px solid var(--topbar-textcolor);
    border-radius: 8px;
    padding: 20px;
    margin: 0;
    cursor: pointer;
}

.containerData {
    display: grid;
    grid-template-columns: min(18vw) 40vw;
    gap: 20px;
}

.containerDataLocals {
    display: grid;
    place-content: center;
    grid-template-columns: 32vw 32vw;
    gap: 20px;
    padding-bottom: 10px;
}

.containerDataRecords {
    display: grid;
    place-content: center;
    grid-template-columns: 340px 340px;
    gap: 20px;
}

.containerDataLocals h5{
    font-size: 13pt;
    text-align: center;
}

.interventionName {
    text-align: center;
    margin: 20px 0;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 0 20px;
    font-size: 13pt;
}

.taskContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 4px;
}

.taskTextNameCheckbox {
    min-width: 279px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    color: var(--text-primary);
    font-size: 11pt;
    border: 0;
    outline: 0;
    border-right: 1px solid var(--input-border);
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    gap: 5px;
    transition: 0.3s ease-in-out;
}

.taskTextNameCheckboxChecked {
    min-width: 279px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    color: var(--text-primary);
    font-size: 11pt;
    border: 0;
    outline: 0;
    border-right: 1px solid var(--input-border);
    background-color: #21ba4520;
    border: 1px solid #21ba4520;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    gap: 5px;
    transition: 0.3s ease-in-out;
}

.taskContainer input {
    border: 0;
    background-color: var(--input-background);
    color: var(--text-primary);
    outline: none;
    width: 100%;
    height: fit-content;
    padding: 12px 10px;
    font-size: 11pt;
}

.localsContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--background-content);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.containerIntervention {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 6px;
    gap: 10px;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
    margin-bottom: 10px;
}

.taskTextName {
    text-align: center;
    width: 40%;
    padding: 12px 10px;
    color: var(--text-primary);
    font-size: 11pt;
    border: 0;
    outline: 0;
    border-right: 1px solid var(--input-border);
}
.taskTextRight {
    text-align: center;
    padding: 12px 10px;
    color: var(--text-primary);
    background-color: var(--input-background);
    font-size: 11pt;
    border-left: 1px solid var(--input-border);
}

.taskText {
    padding: 12px 10px;
    color: var(--text-primary);
    font-size: 11pt;
    border: 1px solid var(--input-border);
}

.checkboxContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.checkboxContainer p {
    font-size: 11pt;
}

.checkbox {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--input-border);
    background-color: var(--background);
    border-radius: 4px;
}

.checkboxChecked {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #38d189;
    border: 1px solid #38d189;
    border-radius: 4px;
    transition: all 200ms;
    animation: ease-in-out;
}

.labelLocals {
    text-align: center;
    font-size: 12pt;
    text-transform: uppercase;
    opacity: 0.9;
    letter-spacing: 0.2px;
    background-color: var(--background-content);
    border-radius: 6px;
    padding: 10px 0;
    margin: 0;
    margin-bottom: 20px;
}

.containerResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    border-radius: 6px;
    white-space: nowrap;
}

.quantityResult {
    max-width: 260px;
    font-size: 26pt;
    font-weight: bolder;
    padding-top: 6px;
    overflow-x: hidden;
    letter-spacing: 0.6px;
}

.resultTaskName {
    white-space: pre-wrap;
    font-size: 13pt;
    padding-bottom: 12px;
    text-align: center;
}

.btnGravar {
    width: fit-content;
    align-self: center;
    position: relative;	
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    margin-bottom: 20px;
    padding: 15px 60px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.btnGravar:hover, .btnGravar:focus {
    background-color: var(--button-bordercolor);
    transition: 200ms ease-in-out;
    animation: ease-in-out;
}