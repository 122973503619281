.content {
    height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
    overflow-y: scroll;
}
  
  @keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.goBack {
    position: fixed;
    margin: 1rem;
    left: 280px;
    top: 45vh;
}

.history {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 1rem;
    right: 20px;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .goBack {
        transition: all .5s;
        left: 3.5rem;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn {
        min-width: 100%;
    }
}

.iconArea {
    width: 60vw;
}

.housekeepingInfo h4 {
    display: flex;
    justify-content: center;
    font-size: 22pt;
    margin: 0;
    margin-top: 20px;
}

.housekeepingInfo {
    cursor: default;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.housekeepingInfo p {
    align-self: center;
    font-size: 14pt;
    margin: 0;
}

.priority {
    align-self: center;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    font-size: 13pt;
    color: var(--background);
    border-radius: 3px;
    letter-spacing: 0.3px;
    font-weight: bold;
    margin-right: 15px;
}

.description {
    margin-top: 20px;
    font-size: 14px;
    color: var(--text-primary);
    border-radius: 4px;
    padding: 8px 12px;
    background-color: var(--background-content);
    letter-spacing: 0.3px;
    height: fit-content;
    font-weight: bold;
}

.iconsAndLabels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.icons {
    display: flex;
    flex-direction: column;
    font-size: 14pt;
}

.iconLabels {
    display: flex;
    flex-direction: column;
}

.icon {
    height: 35px;
    padding: 5px;
}

.iconsLabels h5 {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 15pt;
    margin: 0;
    letter-spacing: 0.5px;
    padding: 5px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.btn {
    width: calc(20vw - 20px);
    position: relative;	
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    margin-top: 20px;
    padding: 15px 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
  
    background: linear-gradient(to left, var(--button-backgroundcolor) 50%, var(--button-bordercolor) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
}
.btnJoin {
    width: calc(20vw - 20px);
    align-self: center;
    position: relative;	
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    margin-top: 20px;
    padding: 15px 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
  
    background: linear-gradient(to left, var(--button-backgroundcolor) 50%, var(--button-bordercolor) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
}

.btnJoin:hover, .btnJoin:focus {
    background-position: left;
    cursor: pointer;
}

.btn:hover, .btn:focus {
    background-position: left;
    cursor: pointer;
}


/* logs */

.logs {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 80%;
}

.log {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.log p {
    margin: 3px;
}

.reason {
    margin-top: 10px;
    border-radius: 6px;
    padding: 5px 10px;
    width: fit-content;
    background-color: var(--topbar-textcolor);
}