.topBar {
    transition: background-color .5s;
    display: flex;
    align-items: center;
    right: 0;
    position: absolute;
    width: calc(100% - 261px);
    margin-left: 261px;
    height: 80px;
    background-color: var(--topbar-backgroundcolor);
    border-bottom: 1px solid var(--topbar-bordercolor);
    text-transform: capitalize;
    user-select: none;
}

.topBar h1 {
    margin: 0;
    margin-left: 20px;
    font-weight: 100;
    color: var(--topbar-textcolor);
}

@media only screen and (max-width: 900px) {
    .topBar {
      transition: all .5s;
      margin-left: 4rem !important;
      width: calc(100% - 4rem - 10px) !important;
    }
  }