@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');

:root {
  --background: #fafafa;
  --background-content: #f0f0f0;
  --text-primary: #1E192F;
  --input-background: #f1f1f1;
  --input-border: #d8d8d8;
  --placeholder-color: rgb(150, 150, 150);
  --button-backgroundcolor: #1E192F;
  --button-bordercolor: #1E192F;
  --button-textcolor: #fafafa;
  --topbar-textcolor: #c9c9c9;
  --topbar-backgroundcolor: #f1f1f190;
  --topbar-bordercolor: #d8d8d8;
  --navbar-backgroundcolor: #2b2442;
  --navbar-bordercolor: #484358;
  --navbar-itemcolor: #484358;
  --listitem-hover: #e7e7e7;
  --goback-btn: #1E192F;
  --boxshadow: #1E192F2c;
  --redirectbtn-backgroundcolor: #fafafa;
  --redirectbtn-bordercolor: #1E192F;
  --redirectbtn-textcolor: #1E192F;
  --redirectbtn-hoverbackground: #f1f1f1;
  --ulheaderactive-backgroundcolor: #292341;
  --ulbodyactive-backgroundcolor: #1e192f75;
  --placeholder-color-opacity: rgb(150, 150, 150, 0.5);
  --text-primary2: #1E192Fe0;
  --text-primary3: #1E192Fd0;
  --text-primary4: #1E192Fc0;
  --text-primary5: #1E192Fb0;
  --graph-text: #f7b900;
  --graph-text-opacity: #f7b90020;
}
[data-theme='dark'] {
  --background: #1a1629;
  --background-content: #26203b;
  --text-primary: #cecece;
  --input-background: #342c50;
  --input-border: #4f4379;
  --placeholder-color: rgb(153, 153, 153);
  --button-backgroundcolor: #1E192F;
  --button-bordercolor: #342c50;
  --button-textcolor: #cecece;
  --topbar-textcolor: #5a546e;
  --topbar-backgroundcolor: #26203b;
  --topbar-bordercolor: #342c50;
  --navbar-backgroundcolor: #1E192F;
  --navbar-bordercolor: #342c50;
  --navbar-itemcolor: #2b2442;
  --listitem-hover: #30284b;
  --goback-btn: #484358;
  --boxshadow: #1e192f50;
  --redirectbtn-backgroundcolor: #1E192F;
  --redirectbtn-bordercolor: #342c50;
  --redirectbtn-textcolor: #cecece;
  --redirectbtn-hoverbackground: #342c50;
  --ulheaderactive-backgroundcolor: #1E192F;
  --ulbodyactive-backgroundcolor: #1512228e;
  --placeholder-color-opacity: rgb(153, 153, 153, 0.5);
  --text-primary2: #cececee0;
  --text-primary3: #cececed0;
  --text-primary4: #cececec0;
  --text-primary5: #cececeb0;
  --graph-text: #e6ac00;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  color: var(--text-primary);
  caret-color: var(--text-primary);
  font-family: "Lato";
}

textarea {
  color: var(--text-primary);
  caret-color: var(--text-primary);
  font-family: "Lato";
}

button {
  font-family: "Lato";
}

label {
  font-family: "Lato";
}

.fade {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-name: popin;
}

@keyframes popin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}