.alert {
    position: fixed;
    right: 50px;
    padding: 1rem 1.5rem;
    width: min(100% - 4rem, auto);
    margin: 1rem;
    opacity: 0;
    color: #fff;
    border-radius: 6px;
    z-index: 123;
    box-shadow: 2px -1px 5px 1px #33333350;
    animation: alert-animation 0.3s 0s ease-out forwards,
      alert-animation-out 0.3s 5s ease-out forwards;
  }
  
  .success {
    background-color: #0ecb81;
  }
  .error {
    background-color: #ff4f5a;
  }
  
  .alertContent {
    margin: 0;
    font-size: 0.8rem;
    height: 100%;
  }
  
  @keyframes alert-animation {
    0% {
      transform: translateX(2rem);
    }
    65% {
      transform: translateX(-0.5rem);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes alert-animation-out {
    0% {
      transform: translateX(0rem);
    }
    65% {
      transform: translateX(-0.5rem);
      opacity: 1;
    }
    100% {
      transform: translateX(2rem);
      opacity: 0;
    }
  }
  