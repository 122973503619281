.content {
    height: calc(100vh - 2rem - 85px);
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
    overflow-y: scroll;
}
  
  @keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.form {
    display: flex;
    flex-direction: column;
    width: 40vw;
    padding: 20px;
    gap: 15px;
    margin-top: 3rem;
}

.formField {
    display: flex;
}

.descriptionFormfield {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.descriptionFormfieldNull {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

@keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

.formField input {
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 11pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0;
    outline: 0;
    border: 1px solid var(--input-border);
}

.formField textarea {
    font-family: 'Lato';
    width: 100%;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.radios {
    display: flex;
    gap: 15px;
    width: 100%;
}

.defaultButton {
    word-break: break-all;
    width: 100%;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--placeholder-color);
    cursor: pointer;
    margin: 5px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.defaultButton:nth-child(1):hover{
    background-color: #64aaff50;
}
.baixa {
    word-break: break-all;
    width: 100%;
    background-color: #64aaff50;
    border: 1px solid #64aaff;
    color: var(--text-primary);
    cursor: pointer;
    margin: 5px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.defaultButton:nth-child(2):hover{
    background-color: #21ba4550;
}
.normal {
    word-break: break-all;
    width: 100%;
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    background-color: #21ba4550;
    cursor: pointer;
    margin: 5px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.defaultButton:nth-child(3):hover{
    background-color: #ff943650;
}
.alta {
    word-break: break-all;
    width: 100%;
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    background-color: #ff943650;
    cursor: pointer;
    margin: 5px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.defaultButton:nth-child(4):hover{
    background-color: #ff4f5a50;
}
.urgente {
    word-break: break-all;
    width: 100%;
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    background-color: #ff4f5a50;
    cursor: pointer;
    margin: 5px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.goBack {
    position: fixed;
    margin: 1rem;
    left: 280px;
    top: 45vh;
}

@media only screen and (max-width: 900px) {
    .goBack {
        transition: all .5s;
        left: 3.5rem;
    }
    .form {
        width: 70vw;
    }
    .descriptionFormfield {
        min-width: 100px;
        word-break: break-all;
    }
    .descriptionFormfieldNull {
        min-width: 100px;
        word-break: break-all;
    }
}

.imageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    width: 170px;
    position: relative;
    box-shadow: 0px 0px 20px 5px #00000010;
    background-color: #00000015;
}

.imageDiv input {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    z-index: 3;
}

.changeImage {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hoverImage);
    border-radius: 4px;
    animation: fade 0.3s ease-in-out;
    z-index: 2;
    cursor: pointer;
    z-index: 1;
}
.selectedFile {
    position: relative;
    width: fit-content;
    align-self: center;
}
.goBackSelectedFile {
    position: absolute;
    top: 15;
    right: 15;
    background-color: #00000015;
    border-radius: 100%;
}
.foto {
    align-self: center;
    width: 200px;
    border-radius: 4px;
    object-fit: cover;
    padding: 10px;
}
.changeImage p {
    color: var(--text);
    margin: 0;
    font-size: 10pt;
    text-transform: uppercase;
}

.submitBtn {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
  
    background: linear-gradient(to left, var(--button-backgroundcolor) 50%, var(--button-bordercolor) 50%) right;
    background-size: 200%;
    transition: 400ms ease-out;
  }
  
  
  .submitBtn:hover, .submitBtn:focus {
    background-position: left;
    cursor: pointer;
  }

.formField textarea::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
}