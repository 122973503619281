.content {
    height: calc(100vh - 2rem - 120px);
    padding: 20px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    overflow-y: scroll;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 10px);
    background-color: var(--background-content);
    border-radius: 8px;
    padding: 10px 0;
}

.containerScroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
}

.container h3 {
    font-weight: bold;
    margin-top: 0;
}

.addBtnInterventions {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 8px 12px;
    transition: all 250ms;
    cursor: pointer;
}

.addBtnInterventions:hover {
    opacity: 1;
}

.addBtnTasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: var(--text-primary);
    opacity: 0.85;
    border-radius: 5px;
    padding: 8px 12px;
    transition: all 250ms;
    cursor: pointer;
}

.addIcon {
    color: var(--background);
}

.addBtnTasks:hover {
    opacity: 1;
}

.search {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0;
    background-color: var(--background);
    border: 1px solid var(--topbar-bordercolor);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 10px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: var(--background);
    color: var(--text-primary);
}

.infoContainer {
    width: 80%;
    margin: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background);
    border-radius: 6px;
    padding: 0 20px;
    font-size: 12pt;
}

.infoContainer p {
    margin: 10px 0;
    text-align: center;
    width: calc((100% / 3) - 5px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.infoContainerLabels {
    align-self: start;
    width: calc(80% - 50px);
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    color: #888;
    font-weight: bold;
    border-radius: 6px;
    font-size: 11pt;
}
.infoContainerLabels p{
    text-align: center;
    width: calc((100% / 3) - 5px);
    margin: 0;
}

.changePages {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.backgroundIcon {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    margin-right: 10px;
    border-radius: 6px;
    background-color: var(--background);
    color: var(--text-primary);
}
.hoverEdit {
    padding: min(15px);
    min-width: 15px;
}

.hoverEdit:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.hoverDelete {
    padding: min(15px);
    min-width: 15px;
}

.hoverDelete:hover {
    color: var(--graph-text);
    cursor: pointer;
}

@media only screen and (max-width: 1160px) {
    .content {
        flex-direction: column;
    }
    .container {
        width: auto;
        margin: 10px 0;
    }
}