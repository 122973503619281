.toggleLabel {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.toggleInput {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleSpan {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #888;
    transition: 0.3s;
    border-radius: 30px;
}

.toggleSpan:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 2.6px;
    background-color: var(--background);
    border-radius: 50%;
    transition: 0.3s;
  }
  
  .toggleInput:checked + .toggleSpan {
    background-color: var(--text-primary);
  }
  
  .toggleInput:checked + .toggleSpan:before {
    transform: translateX(19px);
  }

.toggleStrong {
    position: absolute;
    left: 100%;
    width: max-content;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
}