.body {
  background-color: #00000050;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

  .box {
    background-color: var(--background);
    max-width: 450px;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    animation: box-animation 0.2s ease-out forwards;
  }

  .headerText {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 16pt;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .confirmationText {
    font-size: 13pt;
    margin: 0;
  }

  .warningText {
    margin: 0;
    color: #ff4f5a;
    font-size: 10pt;
  }
  
  .btns {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .btns button {
    border-radius: 6px;
    cursor: pointer;
  }
  
  .btns button:nth-child(1) {
    border: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--text-primary);
    padding: 0 2rem;
    text-underline-offset: 5px;
  }
  
  .btns button:nth-child(2) {
    padding: 0.75rem 2rem;
    border: 0;
    background-color: var(--button-backgroundcolor);
    border: 3px solid var(--button-bordercolor);
    color: var(--button-textcolor);
    transition: 300ms ease-out;
  }
  
  .btns button:nth-child(2):hover, .btns button:nth-child(2):focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
  }

  @keyframes box-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }