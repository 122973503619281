.content {
    height: calc(100vh - 2rem - 85px);
    padding-bottom: 5px;
    background-color: var(--background);
    transition: background-color .5s;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: popin;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
  
  @keyframes popin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes piscar {
    0% {
      background-color: var(--input-background);
    }
    50% {
      background-color: #ff4f5a20;
    }
    100% {
      background-color: var(--input-background);
    }
  }

.goBack {
    position: fixed;
    margin: 1rem;
    left: 280px;
    top: 45vh;
}
.formField {
    display: flex;
}
.formOperation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
    width: 100%;
    min-height: calc(100vh - 23rem - 85px);
}

.submitBtn {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    transition: 300ms ease-out;
  }
  
  
.submitBtn:hover, .submitBtn:focus {
    background-color: var(--button-bordercolor);
    cursor: pointer;
}

.inputLabel {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
}

.inputLabelNull {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 12pt;
    letter-spacing: 0.8px;
    border-top: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    border-left: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--placeholder-color);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    min-width: 140px;
    padding: 8px 0;
    text-align: center;
    animation: piscar 2s ease-in-out infinite;
}

.contaNameInput {
    font-family: 'Lato';
    width: fit-content;
    padding: 13px 16px;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-size: 12pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: 0;
    border: 1px solid var(--input-border);
    margin: 0;
    resize: vertical;
}

.contaNameInput::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
}
.formulaContainer {
    margin-top: 3rem;
    width: calc(100% - 240px);
}

.aroundFormula {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    position: sticky;
    bottom: -10px;
    width: calc(100% - 160px);
    border-top: 1px solid var(--topbar-bordercolor);
    background-color: var(--background);
    padding: 2rem 0;
}
.formulaTitle {
    margin: 0;
    padding: 3rem 0 1.5rem 0;
    text-align: center;
}
.formula {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.conta {
    display: flex;
    align-items: center;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    padding: 8px 16px;
    height: fit-content;
    flex-wrap: wrap;
}

.addValue {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
}

.addValue:hover {
    transition: 200ms ease-in-out;
    background-color: var(--button-bordercolor);
}

.contaName {
    margin: 0;
    display: flex;
    align-items: center;
}

.addValueText {
    text-transform: uppercase;
    text-align: center;
}

.operationsContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chooseValues{
    display: flex;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
    padding: 0 0 1rem 0;
    user-select: none;
    transition: background-color .5s;
    margin-bottom: 1.5rem;
    width: 100%;
    border-bottom: 1px solid var(--topbar-bordercolor);
}

.status {
    color: #888;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 5px;
    font-size: 12pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
}

.activeStatus {
    color: var(--text-primary);
    letter-spacing: 0.5px;
    padding: 5px 10px;
    margin-bottom: 8px;
    font-size: 12pt;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align:center;
    border-bottom: 2px solid transparent;
    transition: all 300ms;
    border: 2px solid var(--text-primary);
    border-radius: 6px;
    background-color: var(--background);
}

.formulaValue {
    margin: 5px;
    background-color: var(--input-background);
    padding: 8px 14px;
    border-radius: 6px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selectedFormulaValue {
    margin: 5px;
    background-color: var(--input-background);
    padding: 8px 14px;
    border-radius: 6px;
    border: 1px solid var(--input-border);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.removeValue {
    position: absolute;
    right: 5px;
    top: 0px;
}

.buttonOperation {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: 3px solid var(--button-bordercolor);
    background-color: var(--background-content);
    color: var(--text-primary);
}
.buttonOperationActive {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: 3px solid var(--button-bordercolor);
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
}

.operations {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: min(500px, 100%);
}

.taskTitle {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14pt;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.search {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background-color: var(--background-content);
    border: 1px solid var(--topbar-bordercolor);
    border-radius: 6px;
}
.search input {
    font-size: 11pt;
    border-radius: 6px;
    padding: 3px 10px;
    width: 15vw;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: var(--text-primary);
}

.dropdown {
    margin-left: 20px;
    width: 45vw;
}

.tasksContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin: 15px 0;
    background-color: var(--input-background);
    border-radius: 6px;
}
.taskName {
    margin: 10px 0 0 0;
    font-size: 12pt;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.tasksActions {
    display: flex;
    gap: 5px;
}


.hoverDelete:hover {
    color: var(--graph-text);
    cursor: pointer;
}

.changePages {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.changePageButtons {
    color: var(--text-primary);
    cursor: pointer;
}

.labelTask {
    margin: 5px 0;
    font-size: 9pt;
    letter-spacing: 0.4px;
}

.btnAddTask {
    background-color: var(--button-backgroundcolor);
    color: var(--button-textcolor);
    border: 3px solid var(--button-bordercolor);
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    font-size: 11pt;
    letter-spacing: 0.4px;
}
.btnAddTask:hover {
    color: var(--graph-text);
}

.taskLabel {
    font-size: 9pt;
    color: var(--placeholder-color);
    margin: 0;
    text-align: end;
}

@media only screen and (max-width: 900px) {
    .goBack {
        transition: all .5s;
        left: 3.5rem;
    }
    .formulaContainer {
        width: calc(100% - 100px);
    }
}